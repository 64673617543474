.App {
  text-align: center;
}

@font-face {
  font-family: "Thin";   
  src: local("Metropolis-Thin"),
    url("./fonts/Metropolis-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Light";   
  src: local("Metropolis-Light"),
    url("./fonts/Metropolis-Light.otf") format("truetype");
}

@font-face {
  font-family: "SemiBold";   
  src: local("Metropolis-SemiBold"),
    url("./fonts/Metropolis-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: "Regular";   
  src: local("Metropolis-Regular"),
    url("./fonts/Metropolis-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Medium";   
  src: local("Metropolis-Medium"),
    url("./fonts/Metropolis-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Black";   
  src: local("Metropolis-Black"),
    url("./fonts/Metropolis-Black.otf") format("truetype");
}

@font-face {
  font-family: "Bold";   
  src: local("Metropolis-Bold"),
    url("./fonts/Metropolis-Bold.otf") format("truetype");
}



