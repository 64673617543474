
.layout {
    display: grid;
    grid-template-columns: 12rem 1fr;
    grid-template-rows: 70px 1fr 70px;
    min-height: 100vh;
  }
  
  .header {
    background-color:#582D82;
    display: flex;
    justify-content:space-between;
        grid-area: 1 / 1 / 2 / 3;
  }
   
  .headertext {
    text-align: left;
    font-weight: regular;
    font-size: 1.1rem;
    color: white;
    position: relative;
    font-family: 'Roboto', sans-serif;
    margin-left:180px;
    transform: translateY(50%);
  }
  
  .logo {
    position: absolute;
    top:1.5%;
    margin-left:35px;
  }

  .navIcon {
    color: white;
    width: 1.5rem;
    height: 1.0rem;
    position: relative;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    margin-left: -200px;
    
  }
  
  .aside {
    grid-area: 2 / 1 / 4 / 2;
  }
  
  .main {
    grid-area: 2 / 2 / 3 / 3;
    grid-area: 2 / 2 / 8 / 8;
  }
  
  .footer {
    background-color: #E0E0E0;
    align-self: end;
    grid-column: 2;
    grid-area: 3 / 1 / 4 / 3;
  }

  .h3text {
    font-family: 'Light';
    text-align: left;
    margin-left:30px;
  }

  .h1text {
    font-family: 'SemiBold';
    font-size: 1.1rem;
  }

  .footertext {
    text-align: left;
    font-weight: regular;
    font-size: 0.7rem;
    color: gray;
    position: relative;
    font-family: 'SemiBold';
    margin-left:20px;
  }
 
  .th {
    position: relative;
    margin-left:30px;
  }

  .thtext {
    background-color: #f7f1f1d7;
    font-family: 'thin';
    font-size: 0.8rem;
  }

  .buttontext {
    background-color:#582D82;
    color: white;
    font-family: 'Thin';
    font-size: 0.8rem;
    width: 100px;
    height: 37px;
  }

  .addtext {
    background-color:#582D82;
    color: white;
    font-family: 'Thin';
    font-size: 0.8rem;
    width: 25px;
    height: 25px;
    opacity:0.6;
  }

  .deletetext {
    background-color:#582D82;
    color: white;
    font-family: 'Thin';
    font-size: 0.8rem;
    width: 120px;
    height: 37px;
  }

  input::placeholder {
    color: gray;
    font-family: 'Light';
    font-size: 1rem;
    background: url(../images/search.svg) no-repeat;
    padding-left: 40px;
  }

  .checkboxwrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.1em;
    height: 1.1em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.1em solid darkgray;
    outline: none;
    cursor: pointer;
    position: absolute;
    margin-left: -20px;
    margin-top: 1px;
    color:black;
  }

  .checkboxwrapper input[type="checkbox"]:checked {
    color:gray;
    cursor: default;
  }

  input {
    width: 255px;
  }
  
  .modalheader .primary {
    color: #337ab7;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .showError {
    border-color: #e03431 !important;
    color: #e03431 !important;
    font-family: 'Regular';
    font-size: 0.8rem;
    margin-left:10px;
    margin-top:-25px;
    margin-bottom:10px;
}
  
.invalid{
  border:1px solid #ff0000
}

 input.error {
    border: 1px solid red;
}

.errorHandler {
  border-color: #e03431 !important;
  color: #e03431 !important;
  font-size: 0.8rem;
  font-family: 'Regular';
  margin-left:10px;
  margin-top:60px;
}

.errorHandlerEntity {
  border-color: #e03431 !important;
  color: #e03431 !important;
  font-size: 0.8rem;
  font-family: 'Regular';
  margin-left:10px;
  margin-top:-20px;
}

.errorHandlerExchange {
  border-color: #e03431 !important;
  color: #e03431 !important;
  font-size: 0.8rem;
  font-family: 'Regular';
  margin-left:1px;
  margin-top:-20px;
}

.successHandler {
  border-color: darkgreen !important;
  color: darkgreen !important;
  font-size: 0.8rem;
  font-family: 'Regular';
  margin-left:10px;
  margin-top:60px;
}

.iconWrapper {
  color: #e03431 !important;
 }

 .delIcon {
  cursor: pointer;
  text-align: left;
  
}

.delTableIcon {
  cursor: pointer;
  text-align: left;
}



.centered {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableButton {
  padding-right: 30px;
}

.pageMessage {
  font-family: 'Regular';
  font-size: 14px;
  white-space: nowrap;
}

table.expense{
  table-layout:fixed;
}

table.expense td{
  overflow: hidden;
  padding-right: 2.5em;
  text-overflow: ellipsis;
}

table.expense th{
  overflow: hidden;
}

.SwitchIcon{
  font-size: 1.3em;
  padding-left: 0.3em;
  color: #582D82;
  display:flex;
  font-display: block;
}

.SearchBarStyle input {
  border: none;
  padding: 1px;
  height: 35px;
  margin: 1px;
  background-color: #F1F5F9;
  font-family: 'Light';
  font-size: 0.8rem;
  width: 115px;
  margin-left: 5px;
  color: gray;

}

.SearchBarStyle input::placeholder {
  color: gray;
  font-family: 'Light';
  font-size: 0.9rem;
  padding-left: 8px;
  background: none no-repeat;
}

.SearchBarStyle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border:none;
}

.fullscreenmodal  {
  min-width: 60%;
}

.fullscreen85modal  {
  min-width: 85%;
}

.fullscreenmodal .modal-content {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background-color: rebeccapurple;
}

.requiredLabel {
  color:red; 
  font-family:"SemiBold";
  font-size: 1rem;
}

.selectionArea{
  min-width:1250px;
}

.table {
  border: 2px red solid;
  display:inline-block;
  max-width: 300px;
}

.errorText {
  border-color: #e03431 !important;
  color: #e03431 !important;
  font-size: 1.8rem;
  font-family: 'Regular';
  text-align: center;
  margin-left:30px;
}

.accountText {
  text-align: left;
  font-size: 0.8rem;
  color: gray;
  position: relative;
  font-family: 'Bold';
  margin-left:20px;
  margin-top:10px;
}

.accountUserText {
  text-align: left;
  font-size: 0.8rem;
  color: darkgray;
  position: relative;
  font-family: 'SemiBold';
  margin-left:20px;
}

.line {
  flex: 1;
  height: 1px;
  background-color: gray;   
  margin-top:10px;
}

.buttonLink {
  margin-left: 1.2rem;
  font-family: SemiBold;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  background-color: transparent;
  border-width: 0;
  color: #582D82;
  border:"none";
  outline: none !important;
}

