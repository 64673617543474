.navItem {
  padding: 1.1rem 1.25rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navItem:hover {
  background-color: #CFDDE2;
}

.navItem:active {
  color: #1e3a8a;
  background-color: #AFC5CD !important;
  cursor: pointer;
}

.navItem:target {
  color: #AFC5CD;
  background-color: #AFC5CD !important;
}

.activeNavItem:active {
  color: #AFC5CD;
  background-color: #AFC5CD;
}

.navIcon {
  color: hwb(252 82% 14%);
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.navLabel {
  color: #0c0d0eef;
  font-size: 0.85rem;
  font-family: "Regular";
}

.navLabelDisabled {
  color: #582D82;
  font-size: 0.85rem;
  font-family: "Bold";
  padding: 1.2rem  1.25rem 0.6rem;
  text-decoration: none;
  display: flex;
  align-items: left;
}

.navItemHeaderButton {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.navItemHeaderButton:active {
  color: #1e3a8a;
  background-color: #1e3a8a;
}

.navItemHeaderChevron {
  color: #d1d5db;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  transition: all 0.25s;
}

.chevronExpanded {
  transform: rotate(180deg);
}

.navChildrenBlock {
  background-color: hsl(226, 71%, 36%);
}
