.modalHeader {
    background: #F4F4F4;
    height: 60px;
    border-bottom: 0 none;
}
  
.modalFooter {
    height: 100px;
    display: flex;
    justify-content: left;
 }

.modalTitle {
    font-family: SemiBold;
    font-size: 1.1rem;
}

.modalBody {
    background: #F4F4F4;
    height: 530px;
    overflow-y: auto;
}

.buttonPrimary {
    background-color :#582D82;
    color: white;
    font-family: Thin;
    font-size: 0.8rem; 
    width: 100px;
    height: 37px;
    border: none;
    border-radius: 0;
    margin-right: 45px;
}

.buttonSecondary {
   background-color :#F4F4F4;
   color: black;
   font-family: Regular;
   font-size: 0.8rem;
   width: 100px;
   height: 37px;
   border: none;
   border-radius: 0;
}

.modal {
  left: 75%;
  margin-top: 3rem;
  width: 400px;
  height: 900px;
  overflow-y: auto;
}

.modalLabel {
 color: black;
 padding-left: 0.5rem;
 padding-bottom: 0.5rem;
 font-family: Regular;
 font-size: 0.85rem;
}

.requiredLabel {
 color: red;
 font-family: SemiBold;
 font-size: 1rem;
}

.deleteModalFooter {
    height: 100px;
    display: flex;
    justify-content: left;
    border-bottom: 0 none;
    border-top: 0 none;
    margin-bottom:0.6rem;
}

.deleteModalBody {
    height: 140px;
    border-bottom: 0 none;
}

.deleteButtonPrimary {
    background-color: #582D82;
    color: white;
    font-family: Thin;
    font-size: 0.8rem;
    width: 100px;
    height: 37px;
    border: none;
    border-radius: "0";
}

.deleteModal {
    left: 40%;
    margin-top: 3rem;
    width: 420px;
}

.deleteModalLabel {
    color: black;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: Regular;
    font-size: 0.85rem
}

.tableModalFooter {
    height: 100px;
    display: flex;
    justify-content: center;
}

.showModalHeader {
    background: #F4F4F4;
    height: 80px;
    border-bottom: 0 none;
}

.showModalLabel {
    color: #582D82;
    padding-left: 0.2rem;
    padding-bottom: 0.5rem;
    font-family: Regular;
    font-size: 0.85rem;
}
   
.showModal {
    left: 40%;
    margin-top: 3rem;
    width: 450px;
    height: 700px;
}

.showModalBody {
    background: #F4F4F4;
    height: 380px;
    overflow: auto;
}

.showModalFooter {
    height: 140px;
    display: flex;
    justify-content: left;
}

.buttonLink {
    margin-left: 8rem;
    font-family: SemiBold;
    font-size: 0.9rem;
    margin-top: 0.3rem;
    background-color: transparent;
    border-width: 0;
    color: #582D82;
}

.buttonLink:hover {
    background-color: transparent;
    color: #582D82;
}

.buttonLink:active {
    --bs-btn-active-bg:transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-color: #582D82;
}

.valuesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.xButton {
    all: unset;
    margin-left: 0.2rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
}

.xButton:hover {
    color: #582D82;
}

.xButton:focus {
    color: #582D82;
}


.value {
    padding: 0.5rem 0.4rem 0.5rem 0.4rem;
    margin: 0 0.5rem 0.2rem 0.1rem;
    font-size: 0.75rem;
    color: #582D82;
    font-family:Regular;
    background-color: #D5DEE2;
    user-select: none;
}

.valuesSelect {
    width:250px;
    padding-left:0.1rem;
}

.checkboxLabel {
   font-family: Thin;
   font-size: 0.9rem;
   color:black;
}

.checkboxLabel:active {
    --bs-btn-active-bg:transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-color: #582D82;
}
