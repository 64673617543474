input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 16px;
  height: 16px;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid darkgrey;
  outline: none;
  cursor: pointer;
}

input:checked {
  background-color: #007a7e;
  position: relative;
  color:#582D82;
}

input.checked::before {
  content: "2714";
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}


input[type="checkbox"]:focus {
  box-shadow: 0 0 20px #007a7e;
}

