.paginationcontainer {
    display: flex;
    list-style-type: none;
    color:green;
}

.paginationcontainer li.paginationitem{
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.paginationitem dots:hover {
    background-color: #582D82;
    cursor: default;
}

.paginationcontainer .paginationitem:hover  {
    background-color: #d5c1e9;
    cursor: pointer;
    color: black;
 }

 .selected {
    background-color: #582D82;
    opacity:0.7;
    color:white;
    font-weight: bold;

 }

 .arrowleft::before{
      position: relative;
      /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
      content: '';
      /* By using an em scale, the arrows will size with the font */
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid black;
      border-top: 0.12em solid black;
      color:white;
 }

 .arrowleft {
    transform: rotate(-135deg) translate(-50%);
  }

  .arrowright::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

  .arrowright  {
    transform: rotate(45deg);
  }

  .arrowright:hover {
    transform: rotate(45deg);
    color:white;
  }

  .paginationcontainer.paginationitem.disabled {
    pointer-events: none;
  }

  .pagination-container.pagination-item.disabled.arrow::before {
      border-right: 0.12em solid  rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid  rgba(0, 0, 0, 0.43);
    }

    .pagination-container.pagination-item.disabled.arrow::before:hover {
      background-color: #582D82;
      cursor: default;
      color:white;
    }
  
  .paginationselect {
    display: inline-block;
    cursor: default;
    padding: 4px;
    margin-left:20px;
    width: 100px;
  }
  
  .paginationdiv {
    margin-top:-5px;
    align-items: center;
  }
  



